<template>
    <div class="my">
        <div class="small-title">
            生活｜工作｜饮食报告
        </div>
        <div class="my-box" v-if="data.name">
            <div class="box-1 flex-between">
                <div>
                    <div class="name-title">
                        {{ data.name ? '你好，'+ data.name : '你好，欢迎来到UitaUita' }}
                    </div>
                    <div class="info">
                        让我们看看哪些需要注意的
                    </div>
                </div>
                <router-link to="/survey" class="btn" v-if="questionState === 1">去填写</router-link>
                <router-link to="/survey" class="btn" v-else-if="questionState ===2">继续填写</router-link>
                <div class="btn" v-else @click="openMass">查看报告</div>
            </div>

            <div class="box-2 flex-between" v-if="questionState === 3">
                <div class="time" style="padding: 17px 0px">
                    报告时间：{{ data.created_at.slice(0, 10).replace('-','.').replace('-','.') }}
                </div>
                <div class="btn2" @click="againSuvery" v-if="!data.order_sn || !data.survey_answer">
                    重新测试
                </div>
            </div>

        </div>

        <div class="kome flex-center" v-else>
           <span style="margin: 0px 7px">额欧，您暂时还没有报告</span>
        </div>

        <div class="coupon" v-if="!data.order_sn && $$(data.user_coupons).length > 0">
            <div class="title">您有一张优惠券待使用</div>
            <coupons-cell :status="1" :data="data.user_coupons[0]" @click="useCoupon"></coupons-cell>
        </div>

        <div class="order flex-between" @click="hrefOrder" v-if="data.order_sn">
            <div class="left flex">
                我的订单
            </div>
            <div class="right flex">
                <router-link :to="`/product/success/${data.order_sn}`" style="margin-right: 5px"
                             v-if="data.is_address == 0">
                    请尽快填写地址
                </router-link>
                <van-icon name="arrow" size="22" color="#999999" style="margin-right: -7px"/>
            </div>
        </div>

        <div class="small-title" v-if="data.report_id >= 1">
            遗传｜基因｜营养代谢报告
        </div>
        <div class="my-box" v-if="data.report_id >= 1">
            <div class="box-1 flex-between" style="padding-bottom: 10px">
                <div>
                    <div class="name-title">
                        你好，{{ data.name }}
                    </div>
                    <div class="info">
                        科学的了解您的身体状况
                    </div>
                    <div class="info">
                        我们来探索一下吧！
                    </div>

                </div>
                <router-link  :to="`/mytest/${data.record_id}`" class="btn">查看报告</router-link>
            </div>
            <div class="box-2 flex-between">
                <div class="time" style="padding: 17px 0px">
                    报告时间：{{ data.created_at.slice(0, 10) }}
                </div>
<!--                <div class="btn2" @click="againSuvery">-->
<!--                    重新测试-->
<!--                </div>-->
            </div>
        </div>

        <div class="small-title" v-if="data.nutrition_plan_id >=1">
            我的每日定制营养包
        </div>

        <div class="my-box" v-if="data.nutrition_plan_id >=1">
            <div class="box-1 flex-between">
                <div>
                    <div class="name-title">
                        你好，{{ data.name }}
                    </div>
                    <div class="info">
                        第一个90天，让我们给您准备这些
                    </div>
                    <div class="info">
                        每天一小包，让健康方便简单
                    </div>

                </div>
                <router-link :to="`/scheme/${data.nutrition_plan_id}/${data.order_sn}`" class="btn">
                    查看报告
                </router-link>
            </div>
        </div>

        <div class="end" v-if="data.nutrition_plan_id >=1" style="margin-bottom:20px">
            ——主人，我会尽快通过海外直运到您手里——
        </div>

        <div :class="['aside', 'flex',isAside ? 'active':'']">
            <img src="https://khome2.tuzuu.com/vita/kefu.png" @click="isAside =! isAside"/>
            <router-link to="/my/consult">专属营养顾问</router-link>
        </div>
    </div>
</template>

<script>
    import CouponsCell from "../../components/CouponsCell";
    import {Toast} from "vant";

    export default {
        name: "",
        data() {
            return {
                isAside: true,
                questionState: 1,//问卷状态，1是去填写，2是继续填写，3是查看报告
                data: {
                    report_id: ''
                },
                w: {
                    total: '100',
                    title: '测试',
                    end_time: '2020-5-05',

                }
            }
        },
        created() {
            this.getData()
        },
        components: {
            CouponsCell
        },
        methods: {

            getData() {
                let toast = Toast.loading({
                    message: '加载中',
                    forbidClick: true,
                    overlay: true
                })
                this.$request.get("/my").then(res => {
                    console.log(res)
                    if (res.code == 1) {
                        toast.clear()
                        this.data = res.data
                        let name = window.localStorage.getItem("name")
                        console.log(name)
                        //假如，没有问卷结果，也没用本地名字，为刚进入
                        if (this.data.record_id <= 0 && !name) {
                            this.questionState = 1
                            //没有结果，有缓存，为继续
                        } else if (this.data.record_id <= 0 && name) {
                            this.questionState = 2
                            this.data.name = name
                        } else if (this.data.record_id > 0) {
                            this.questionState = 3
                            window.localStorage.setItem("name",res.data.name)
                        }else if(!this.data.record_id && name){
                            this.questionState = 2
                            this.data.name = name
                        }
                    }
                })
            },
            //查看报告
            openMass() {
                let toast = Toast.loading({
                    message: '报告生成中',
                    forbidClick: true,
                })
                this.$request.post("/question_result", {
                    question_list: this.data.survey_answer,
                    record_id:this.data.record_id
                }).then(res => {
                    if (res.code == 1) {
                        window.sessionStorage.setItem("answer", JSON.stringify(res.data))
                        window.localStorage.setItem("name", this.data.name)
                        toast.clear()
                        this.$router.push({
                            path: '/mass'
                        })
                    } else {
                        Toast(res.message)
                    }
                })
            },
            //查看订单
            hrefOrder() {
                if (this.data.is_address === 1) {
                    this.$router.push({
                        path: `/product/show/${this.data.order_sn}`
                    })
                }
            },
            //重新测试
            againSuvery() {
                this.$dialog.confirm({
                    title: `Hi,${this.data.name}`,
                    message: '重新测试的话我们将再认识一遍\n之前的记录将被清除咯',
                    confirmButtonText: '我就要重新做',
                    cancelButtonText: '不做了不做了',
                    confirmButtonColor:"black"
                }).then(() => {
                    this.$router.push({
                        path: '/survey'
                    })
                }).catch(() => {
                })
            },
            //使用优惠券
            useCoupon() {
                this.$router.push({
                    path: `/product/order/${this.data.user_coupons[0].goods_ids[0]}`
                })
            }
        }
    }
</script>

<style scoped lang="less">
.kome{
    height: calc(100vh - 70px);
    color: #666666;
    font-size: 14px;
    &:before,&:after{
        content: '';
        width: 15px;
        height: 1px;
        background: #666666;
    }
}
    .my {
        font-size: 16px;
        min-height: 100vh;
        background-color: #F3F3F4;
        padding: 15px 10px;
        box-sizing: border-box;

        .small-title {
            font-size: 14px;
            color: #666666;
            //font-weight: 700;
            margin-bottom: 10px;
        }

        .end {
            font-size: 16px;
            color: #666666;
            padding-left: 10px;
            box-sizing: border-box;
        }

        .aside {
            font-size: 14px;
            color: white;
            background-color: #9E3CF5;
            position: fixed;
            right: 0;
            bottom: 30px;
            padding: 16px 18px;
            padding-right: 8px;
            border-radius: 70px 0px 0px 70px;
            transition: all 0.4s;

            img {
                width: 23px;
                margin-right: 8px;
            }

            a {
                color: white;
            }
        }

        .active {
            transform: translateX(92px);
        }

        .coupon {
            margin-bottom: 25px;

            .title {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 10px;
                margin-top: 25px;
            }
        }
    }

    .my-box {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 15px;
        padding: 26px 15px 0px 15px;
        box-sizing: border-box;

        .box-1 {

            padding-bottom: 20px;

            .name-title {
                font-size: 20px;
                color: #292C2F;
                line-height: 25px;
                font-weight: 900;
                //font-family:fantasy;
                margin-bottom: 9px;
            }

            .info {
                font-size: 13px;
                color: #3F3F3F;
                height: 22px;
                font-weight: 500;
                letter-spacing:0.5px;
            }

            .btn {
                border-radius: 5px;
                color: white;
                font-size: 15px;
                background-color: #6B00AF;
                padding: 9px 24px;
            }
        }

        .box-2 {
            border-top: 1px solid #292C2F;

            .time {
                font-size: 14px;
                color: #666666;
            }

            .btn2 {
                border: 1px solid #979797;
                border-radius: 5px;
                padding: 6px 16px;
                margin: 11px 0px;
                font-size: 15px;
                color: #292C2F;
            }
        }
    }

    .order {
        width: 100%;
        padding: 11px 17px 11px 15px;
        background-color: white;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 20px;

        .left {
            font-size: 15px;
            font-weight: 700;
            color: #3F3F3F;


            &:before {
                content: '';
                display: block;
                height: 24px;
                width: 4px;
                border-radius: 10px;
                background-color: #6B00AF;
                margin-right: 7px;
            }
        }

        .right {
            font-size: 14px;
            color: #999999;
            margin-top: 2px;

            a {
                color: #999999;
            }
        }
    }


</style>