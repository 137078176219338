<template>
    <div class="coupons flex-center" :class="{'not-use':data.status === 3 || data.status === 4 || data.status === 0 }">
        <div class="coupon-left flex-column-center">
            <div class="round"></div>
            <div class="round"></div>
            <div class="money">
                <span style="font-size: 18px;margin-right: 2px">¥</span>{{parseInt(data.sale_off || 0)}}
            </div>
            <div class="tag">下单立减</div>
        </div>
        <div class="coupon-right flex-between">
            <div class="coupons-info">
                <div>{{data.title}}</div>
                <div class="time" v-if="data.receive_expire && data.status === 1">有效至：领取后{{data.receive_expire}}天内有效</div>
                <div class="time" v-else>有效至: {{data.expire_date}}</div>
            </div>
            <div class="btn flex-center" v-if="status === 1" @click="handClick">
                立即使用
            </div>
            <div class="state" v-if="status === 0 ||status === 4 || status === 3">
                <img :src="status === 4 ? 'https://khome2.tuzuu.com/vita/coup_1.png' : 'https://khome2.tuzuu.com/vita/coup_2.png'">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        props:{
            status:{
                type:Number   // 0 失效 1 正常 2 已领取 3 已过期 4 领完
            },
            data:{
                type:Object   // 0 失效 1 正常 2 已领取 3 已过期 4 领完
            }
        },
        methods:{
            handClick(){
                this.$emit("click")
            }
        }
    }
</script>

<style scoped lang="less">
    .coupons {
        overflow: hidden;
        border-radius: 5px;
        align-items: normal !important;
    }

    .coupon-left {
        padding: 23px 9px;
        position: relative;
        background-image: linear-gradient(360deg, #A400D8 0%, #6B00AF 100%);
        justify-content: center;
        width: 30%;
        .round {
            width: 14PX;
            height: 14PX;
            border-radius: 50%;
            background-color: #F3F3F4;
            right: -7PX;
            position: absolute;
            z-index: 5;
            &:first-of-type {
                top: -7px;
            }

            &:nth-of-type(2) {
                bottom: -7px;

            }
        }

        .money {
            width: 100%;
            text-align: center;
            color: white;
            font-size: 25px;
            margin-bottom: 5px;
            font-weight: 700;
        }

        .tag {
            color: white;
            font-size: 13px;
            text-align: center;
            width: 100%;
            padding: 0px 5px;
            box-sizing: border-box;

            white-space: nowrap;
        }
    }

    .coupon-right {
        width: 100%;
        background-color: white;
        font-size: 18px;
        color: #3F3F3F;
        position: relative;

        .coupons-info{
            padding: 25px 7px;
            line-height: 20px;
            box-sizing: border-box;
            font-weight: 700;
            .time{
                font-size: 14px;
                color: #666666;
                margin-top: 10px;
                font-weight: 400;
            }
        }
        .btn{
            position: absolute;
            height: 100%;
            border-left: 1px dashed #6B00AF;
            padding-left: 12px;
            box-sizing: border-box;
            width: 30px;
            right: 10px;
            font-size: 14px;
            color: #6B00AF;
            font-weight: 700;
        }
        .state{
            position: absolute;
            right: 0;
            top: 0;
            opacity: 1;
            img{
                width: 52px;
            }
        }

    }
    .not-use{
        .coupon-left{
            opacity: 0.5;
        }
        .coupons-info{
            opacity: 0.5;
        }
    }
</style>